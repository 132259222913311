<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- <el-button @click="initGoods">初始化</el-button> -->
    
    <div class="row no-gutters">
      <div class="col-6 pr-1" @click="$router.push({path: '/order/order-lis?type=2'})">
        <el-card shadow="hover" class="data-card">
          <div class="row no-gutters">
            <div class="data-card-left"><img src="/img/today-order.png" alt=""/></div>
            <div>
              <div class="data-card-right-text">今日订单总数</div>
              <div class="cs" style="color:#ff1522">
                {{ (dashBoardData.todayOrderNum || 0) }}
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <div class="col-6 pl-1" @click="$router.push({path: '/statistics/census'})">
        <el-card shadow="hover" class="data-card">
          <div class="row no-gutters">
            <div class="data-card-left"><img src="/img/total-sales-today.png" alt=""/></div>
            <div>
              <div class="data-card-right-text">今日销售总额</div>
              <div class="cs" style="color:#00d600">
                {{ (dashBoardData.totdaySumPrice || 0) | unitPrice }}
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <div class="col-6 mt-2 pr-1" @click="$router.push({path: '/order/order-lis?type=3'})">
        <el-card shadow="hover" class="data-card">
          <div class="row no-gutters">
            <div class="data-card-left"><img src="/img/yesterday-order.png" alt=""/></div>
            <div>
              <div class="data-card-right-text">昨日订单总数</div>
              <div class="cs" style="color:#059fee">
                {{ (dashBoardData.yesterdayOrderNum || 0) }}
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <div class="col-6 mt-2 pl-1" @click="$router.push({path: '/statistics/census'})">
        <el-card shadow="hover" class="data-card">
          <div class="row no-gutters">
            <div class="data-card-left"><img src="/img/total-sales-yesterday.png" alt=""/></div>
            <div>
              <div class="data-card-right-text">昨日销售总额</div>
              <div class="cs" style="color:#fe9d01">
                {{ (dashBoardData.yesterdaySumPrice || 0) | unitPrice }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <div class="quickLink">
      <div class="title">运营快捷入口</div>
      <div class="linkBox">
        <router-link to="/finance/AccountManage/accountreviw">
          <img src="/icons/user-b.png"/>账户管理
        </router-link>
        <router-link to="/enterp/enterplist">
          <img src="/icons/enterprise-b.png"/>企业管理
        </router-link>
        <router-link to="/page/floor">
          <img src="/icons/market-b.png"/>集市管理
        </router-link>
        <router-link to="/finance/AccountManage/accountreviw">
          <img src="/icons/finance-b.png"/>财务管理
        </router-link>
        <router-link to="/supplier/supplier-list">
          <img src="/icons/supplier-b.png"/>供应商管理
        </router-link>
      </div>
    </div>

    <div class="line">
      <div class="title">订单统计</div>
      <div class="store-index-content order">
        <div class="orderLeft">
          <div class="orderBox">
            <h2>本月订单总数</h2>
            <h3>{{ orderTotalNum }}</h3>
            <!-- <p><span class="red">10%</span>同比上周</p> -->
          </div>
          <!-- <div class="orderBox">
                          <h2>本周订单总数</h2>
                          <h3>10000</h3>
                          <p><span class="green">10%</span>同比上周</p>
          </div>-->
        </div>
        <div class="orderRight">
          <div class="orderTab">
            <div class="tabBtn">
              <!-- <span>今日</span>
                              <span>本周</span>
              <span>本月</span>-->
              <el-date-picker
                v-model="selectMonth"
                type="month"
                placeholder="请选择月"
                @change="handleShopSumaryStatistics"
                value-format="yyyy-MM"
              ></el-date-picker>
            </div>
            <h4>{{ name }}</h4>
          </div>
          <div id="trafficStatistics" style="height: 360px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_Common from '@/api/common';
import request from '@/utils/request';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  name: 'dashboard',
  components: {
    XDialog
  },
  data() {
    return {
      selectMonth: '',
      sesalChart: '',
      orderTotalNum: '0',
      name: '订单统计',
      params: {
        year: '2020',
        month: '6',
        cycle_type: 'MONTH'
      },
      /** 加载中*/
      loading: false,

      /** 商家基本信息 */
      shop_info: this.$store.getters.shopInfo,

      /** 首页统计数据*/
      dashBoardData: {},

      /** 商城公告*/
      shop_announcement: [],

      /** 平台联系方式*/
      concat: null,

      /** 商家图标*/
      fileList: [],

      /** 视图高度*/
      tableHeight:
        (document.body.clientHeight - 84 - 80 - 80 - 20 - 20 - 4) / 2,

      /** 当前商城公告名称 */
      currentName: '',

      /** 当前商城公告内容 */
      currentContent: '',

      /** 是否显示商城公告 默认不显示 */
      isShowArticle: false,
    };
  },
  mounted() {
    //获取当前时间
    var now = new Date();
    var monthn = now.getMonth() + 1;
    var yearn = now.getFullYear();

    this.selectMonth = yearn + '-' + monthn;

    API_Common.getIndexDDnum().then(res => {
      this.dashBoardData = res;
      // console.log(res,"resresresres")
    });
    if (1) {
      this.$nextTick(() => {
        this.sesalChart = this.$echarts.init(
          document.getElementById('trafficStatistics')
        );
      });
      window.onresize = () => {
        this.sesalChart.resize();
      };
      this.handleShopSumaryStatistics();
    }
  },
  methods: {
    //el 初始化
    initGoods() {
      request({
        url: '/admin/goods/search/shopgroods',
        method: 'get',
        loading: false
      })
    },
    /** 店铺总流量*/
    handleShopSumaryStatistics() {
      // console.log(params,"dddd")
      var str = this.selectMonth.split('-');

      this.params.year = Number(str[0]);
      this.params.month = Number(str[1]);
      console.log(str, 'resresresres');
      API_Common.getOrderNum(this.params).then(response => {
        this.orderTotalNum = response.orderTotalNum;
        this.name = response.chart.series.name + '订单统计';
        this.loading = false;
        this.sesalChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: response.chart.xAxis
          },
          grid: {
            left: '40px',
            right: '20px',
            top: '10%',
            bottom: '0%',
            containLabel: true
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              //          data: response.chart.series.data,
              //          type: "line",
              //          smooth: true, //这句就是让曲线变平滑的
              //          symbol: "none", //这句就是去掉点的
              //          areaStyle: {},
              //          color: "",

              type: 'line',
              data: response.chart.series.data,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1a43a9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#c2bbfe'
                    },
                    {
                      offset: 1,
                      color: '#d3edfb'
                    }
                  ])
                }
              },
              barWidth: 50,
              areaStyle: {
                normal: {}
              },
              symbol: 'none',
              smooth: true
            }
          ]
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.data-card {
  padding: 26px 23px;
  cursor: pointer;
  .data-card-left{
    margin-right: 29px;
    img {
      width: 85px;
      height: 85px;
    }
  }
  .data-card-right-text {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 1;
    font-weight: 800;
    color: #393C41;
    font-family: PingFang;
  }
  ::v-deep .el-card__header {
    border-bottom: 0;

    img {
      width: 26px;
      height: 26px;
    }

    .col {
      margin-right: 10px;
      padding-left: 12px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .cs {
    height: 38px;
    line-height: 1;
    font-size: 50px;
    font-family: DINCond;
    font-weight: 900;
  }
}


/*商家基本信息*/
div.grid-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  /*logo*/
  .logo {
    flex-grow: 1;
    padding: 15px;
    position: relative;
    box-sizing: content-box;

    div.shop-img-icon {
      width: 200px;

      &:hover span.to-change-shop-icon {
        display: block;
      }

      span.to-change-shop-icon {
        display: none;
        position: absolute;
        left: 16px;
        bottom: 20px;
        z-index: 10;
        width: 200px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        background: rgba(33, 33, 33, 0.6);
        color: #fff;
        cursor: pointer;
      }

      img {
        width: 200px;
        height: 80px;
        cursor: pointer;
        border: 1px solid #999;
      }
    }
  }

  /*商家信息*/
  .shop-info {
    flex-grow: 40;
    padding-top: 30px;
    /*商家主营信息*/
    .shop-info-basic {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      span:first-child {
        color: #333;
        font: 18px/36px "microsoft yahei";
        letter-spacing: normal;
        vertical-align: bottom;
        word-spacing: normal;
      }

      span:last-child {
        color: #999;
        font-size: 12px;
        letter-spacing: normal;
        line-height: 28px;
        margin-left: 12px;
        vertical-align: bottom;
        word-spacing: normal;
      }
    }

    /*卖家信用*/
    .shop-info-credit {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      ::v-deep span {
        display: inline-block;
        margin-left: 0 !important;
        color: #999;
        font-size: 12px;
        letter-spacing: normal;
        line-height: 28px;
        vertical-align: bottom;
        word-spacing: normal;
      }

      .shop-icon {
        margin: 0 40px;
      }
    }
  }

  /*动态评分*/
  .score {
    flex-grow: 2.5;
    text-align: right;
    padding: 5px 20px;

    p {
      margin: 0;
      text-align: left;
      border-bottom: 1px dotted #e7e7e7;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding-bottom: 5px;
    }

    div.active-score {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      margin-top: 5px;

      div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;

        span:first-child {
          color: #333;
          display: inline-block;
          font-size: 14px;
          text-align: center;
          padding: 5px 0px;
          font-weight: bold;
        }

        span:last-child {
          display: inline-block;
          font-size: 14px;
          text-align: center;
          padding: 5px 0px;
          color: #da542e;
        }
      }
    }
  }
}

/* 店铺商品提示/交易订单提示*/
h1 {
  border-left: 3px solid #28b779;
  font: 16px/18px "microsoft yahei";
  height: 18px;
  margin-bottom: 4px;
  padding-left: 6px;
  margin-left: 17px;
}

/*副标题*/
h2 {
  color: #aaa;
  font: 12px/16px "microsoft yahei";
  margin: 10px 0;
}

/* 店铺/交易提示内容 */
.store-index-content {
  margin: 20px 0;
  overflow: hidden;

  .store-rompt {
    clear: both;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    cursor: pointer;
  }

  /** 标签 */
  .el-tag {
    cursor: pointer;
  }
}

/*商城公告*/
.store-bulletin {
  color: #aaa;
  cursor: pointer;
  font: 12px/16px "microsoft yahei";
  margin: 10px 0;

  &:hover a {
    color: #337ab7;
  }
}

/*平台联系方式*/
.platform-concate {
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    color: #aaa;
    font: 12px/16px "microsoft yahei";
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    span {
      display: inline-block;

      ::v-deep p {
        margin: 0;
      }
    }
  }
}
</style>
<style scoped>
.dashboard-container {
  padding-top: 1px;
  padding-bottom: 1px;
}

.mt-2{
  margin-top: 14px !important;
}

.tip {
  margin: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #eee;
  line-height: 36px;
  padding-left: 20px;
}

.tip span {
  color: red;
}

.link ::v-deep .el-card__body {
  min-height: 0;
}

.order {
  overflow: hidden;
}

.order .orderLeft {
  float: left;
  width: 200px;
  padding: 30px;
  border-right: 1px solid #eee;
  height: 390px;
}

.order .orderRight {
  float: right;
  width: calc(100% - 200px);
}

.orderBox {
  margin-bottom: 70px;
}

.orderBox h2 {
  font-size: 18px;
  color: #999;
  line-height: 20px;
  margin: 0;
}

.orderBox h3 {
  font-size: 24px;
  color: #393c41;
  font-weight: 600;
  line-height: 42px;
  margin: 0;
}

.orderBox p {
  font-size: 16px;
  color: #ccc;
  line-height: 20px;
  margin: 0;
}

.orderTab {
  padding-right: 20px;
}

.orderTab h4 {
  line-height: 32px;
  margin: 0;
  text-indent: 40px;
  font-size: 18px;
  color: #393c41;
}

.tabBtn {
  float: right;
}

.tabBtn span {
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #393c41;
}

.tabBtn span.active {
  color: rgb(26, 188, 156);
}

.boxCard {
  border-radius: 10px;
  background: #fff;
  padding-left: 36px;
}

.boxCard .hr {
  width: 552px;
  background: #f5f6f9;
  height: 1px;
  margin: 5px auto 5px;
}

.boxCard .box {
  padding-bottom: 40px;
  padding-top: 40px;
}

.boxCard .box h2 {
  padding-top: 42px;
  padding-left: 40px;
  font-size: 30px;
  color: #393c41;
  font-weight: 600;
  line-height: 48px;
  margin: 0;
}

.boxCard .box h2 img {
  width: 48px;
  height: 48px;
  margin-right: 22px;
  margin-top: -4px;
}

.quickLink {
  margin: 30px 0;
}

.title {
  color: #393c41;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
  position: relative;
  padding-left: 10px;
}

.title:before {
  content: "";
  display: block;
  width: 4px;
  height: 16px;
  background: #1a43a9;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.linkBox {
  font-size: 0;
  line-height: 0;
}

.linkBox a {
  display: inline-block;
  width: 170px;
  height: 70px;
  border-radius: 7px;
  line-height: 70px;
  font-size: 12px;
  color: #393c41;
  margin-right: 10px;
  background: #fff;
  text-align: center;
}

.linkBox a:last-child {
  margin-right: 0;
}

.linkBox a img {
  margin-right: 10px;
  margin-top: -4px;
  width: 27px;
  height: 27px;
}

.app ul {
  font-size: 0;
  line-height: 0;
  list-style: none;
  padding: 0;
}

.app ul li {
  display: inline-block;
  width: calc(20% - 16px);
  text-align: center;
  padding-top: 40px;
  box-sizing: border-box;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 20px;
  background: #fff;
  border-radius: 10px;
  height: 480px;
  margin-right: 20px;
}

.app ul li:last-child {
  margin-right: 0;
}

.app ul li img {
  display: block;
  width: 118px;
  height: 118px;
  margin: auto;
  margin-bottom: 10px;
}

.app ul li h4 {
  font-size: 18px;
  color: #393c41;
  line-height: 40px;
}

.app ul li p {
  text-align: left;
  font-size: 16px;
  color: #585c66;
  line-height: 28px;
  height: 116px;
}

.app ul li span {
  display: block;
  font-size: 18px;
  color: #333333;
  margin-bottom: 22px;
  line-height: 18px;
}

.order {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
}
</style>
